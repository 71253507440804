import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    routes: [
        { 
            path: "/",
            name: "LandingPage", 
            component: () => import('../components/LandingPage')
        },
        { 
            path: "/features",
            name: "posibilities", 
            component: () => import('../components/possibilities')
        },
        { 
            path: "/resources",
            name: "resources", 
            component: () => import('../components/resources')
        },
        { 
            path: "/price",
            name: "price", 
            component: () => import('../components/tarffs')
        },
        { 
            path: "/price-archive",
            name: "price-archive", 
            component: () => import('../components/tarffs-archive')
        },
        { 
            path: "/modules",
            name: "modules", 
            component: () => import('../components/1CModul')
        },
        { 
            path: "/videoLessons",
            name: "videoLessons", 
            component: () => import('../components/videolessons')
        },
        { 
            path: "/news",
            name: "news",
            component: () => import('../components/news.vue')
        },
        { 
            path: "/public-offer",
            name: "public-offer",
            component: () => import('../components/PublicOffer.vue')
        },
        { 
            path: "/articles",
            name: "articles",
            component: () => import('../components/Articles.vue')
        },
        {
            path:'/articles/automatic-invoice-generation',
            name: 'article1',
            component: () => import('../components/articles/Article1.vue')
        },
        {
            path:'/articles/how-to-create-custom-documents',
            name: 'article2',
            component: () => import('../components/articles/Article2.vue')
        },
        {
            path:'/articles/document-reconciliation-act',
            name: 'article3',
            component: () => import('../components/articles/Article3.vue')
        },
        {
            path:'/articles/how-to-issue-invoices-for-marked-goods',
            name: 'article4',
            component: () => import('../components/articles/Article4.vue')
        },
        {
            path:'/articles/download-individual-reports',
            name: 'article5',
            component: () => import('../components/articles/Article5.vue')
        },
        {
            path:'/petition',
            name: 'petition',
            component: () => import('../components/Petition.vue')
            
        },
        { 
            path: "*",
            name: "404",
            component: () => import('../components/404mistake.vue')
        },
        { 
            path: "/thanks",
            name: "thanks",
            component: () => import('../components/ThanksPage.vue')
        },
        { 
            path: "/newUsers/toThankPage",
            name: "newUsersThank",
            component: () => import('../components/newUsers/thankPage')
        }
    ],
})
router.afterEach(() => {
    window.scrollTo(0,0);
})


export default router